////////// SIDEBAR ////////
$sidebar-width-lg: 234px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;


$sidebar-gradient-top: #482fd1;
$sidebar-gradient-bottom: #371c79;
$sidebar-dark-bg: linear-gradient(to bottom, $sidebar-gradient-bottom, $sidebar-gradient-top, $sidebar-gradient-bottom);
$sidebar-dark-menu-color: #927cf9;
$sidebar-dark-submenu-color: #927cf9;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color: #fff;
$sidebar-dark-menu-hover-bg: #fff;
$sidebar-dark-menu-hover-color: #fff;
$sidebar-dark-submenu-hover-color: #fff;
$sidebar-dark-submenu-active-color: #927cf9;
$sidebar-dark-menu-icon-color: #927cf9;
$sidebar-dark-menu-arrow-color: #927cf9;
$sidebar-dark-profile-name-color: #ffffff;
$sidebar-dark-profile-title-color: #ffffff;


$sidebar-light-bg: #fff;
$sidebar-light-menu-color: #2717c9;
$sidebar-light-menu-active-bg: initial;
$sidebar-light-menu-active-color: #2717c9;
$sidebar-light-menu-hover-bg: transparant;
$sidebar-light-menu-hover-color: #2717c9;
$sidebar-light-submenu-color: $sidebar-light-menu-color;
$sidebar-light-submenu-hover-bg: initial;
$sidebar-light-submenu-hover-color: #2717c9;
$sidebar-light-menu-icon-color: $sidebar-light-menu-color;
$sidebar-light-profile-name-color: #ffffff;
$sidebar-light-profile-title-color: #8d9498;

$sidebar-menu-font-size: .875rem;
$sidebar-menu-padding-left: 0rem;
$sidebar-menu-padding-right: 0rem;
$sidebar-menu-padding-top: .937rem;
$sidebar-menu-padding-bottom: .937rem;

$sidebar-submenu-padding: .25rem 0 0 0rem;
$sidebar-submenu-font-size: .75rem;
$sidebar-submenu-item-padding: 0.625rem 2rem;

$sidebar-icon-font-size:  1.125rem;
$sidebar-arrow-font-size: .625rem;
$sidebar-prgressbar-bg: rgba(255,255,255, 0.3);

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 1.5rem 0 1.5rem;

$sidebar-box-shadow: 6px 16px 31px -18px #b7bcd1;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: #fff;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: $white;
$navbar-height: 70px;
$navbar-menu-color: $white;
$navbar-font-size: 1rem;
$navbar-icon-font-size: .9375rem;
$navbar-search-width: 18.75rem;

///////// NAVBAR ////////