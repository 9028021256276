@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins/RPoppins-Light.eot');
  src: url('../../fonts/poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/poppins/Poppins-Light.woff2') format('woff2'),
      url('../../fonts/poppins/Poppins-Light.woff') format('woff'),
      url('../../fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins/Poppins-Bold.eot');
  src: url('../../fonts/poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/poppins/Poppins-Bold.woff2') format('woff2'),
      url('../../fonts/poppins/Poppins-Bold.woff') format('woff'),
      url('../../fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins/Poppins-Medium.eot');
  src: url('../../fonts/poppins/Poppins-Medium?#iefix') format('embedded-opentype'),
      url('../../fonts/poppins/Poppins-Medium.woff2') format('woff2'),
      url('../../fonts/poppins/Poppins-Medium.woff') format('woff'),
      url('../../fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins/Poppins-Regular.eot');
  src: url('../../fonts/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/poppins/Poppins-Regular.woff2') format('woff2'),
      url('../../fonts/poppins/Poppins-Regular.woff') format('woff'),
      url('../../fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins/Poppins-Black.eot');
  src: url('../../fonts/poppins/Poppins-Black.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/poppins/Poppins-Black.woff2') format('woff2'),
      url('../../fonts/poppins/RPoppins-Black.woff') format('woff'),
      url('../../fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

